
import { Vue, Component, Prop, VModel } from "vue-property-decorator";
import { CollapseTransition } from "@ivanv/vue-collapse-transition";

export interface AccordionItem {
  title: string;
  value?: string;
  itemClass?: string | string[] | Record<string, any>;
}
export type AccordionData = AccordionItem[];

@Component({
  name: "b-accordion",
  components: { CollapseTransition },
})
export default class BAccordion extends Vue {
  @VModel({ type: Number, default: -1 }) active!: number;
  @Prop([Array, Object]) readonly items!: AccordionData;

  get mappedItems(): AccordionData | undefined {
    if (!this.items || !this.items.length) {
      return undefined;
    }

    return this.$_.map(this.items, (obItem) => {
      if (!obItem.value) {
        const sValue = this.$_.kebabCase(obItem.title);
        this.$_.set(obItem, "value", sValue);
      }

      return obItem;
    });
  }

  activate(index: number): void {
    this.$emit("input", index === this.active ? -1 : index);
  }
}
