
import {
  CourseData,
  Section,
  SectionCollection,
  SectionData,
} from "@planetadeleste/vue-mc-learning";
import { Component, Prop, Vue } from "vue-property-decorator";
import BAccordion, { AccordionData } from "@/components/bs/BAccordion.vue";
import CourseLessons from "@/modules/courses/components/course/CourseLessons.vue";
import CourseSectionTitle from "@/modules/courses/components/course/CourseSectionTitle.vue";
import { AuthModule } from "@/store/auth";
import { each, filter, map, set } from "lodash";

type AccordionSections = AccordionData | SectionData[];

@Component({
  components: { BAccordion, CourseLessons, CourseSectionTitle },
})
export default class CourseSections extends Vue {
  @Prop(Object) readonly course!: CourseData;
  obSections: SectionCollection = new SectionCollection();
  iActiveItem = -1;

  get arItems(): AccordionSections {
    if (!this.obSections.length) {
      return [];
    }

    return map(this.obSections.models, (obModel: SectionData) => {
      set(obModel, "title", obModel.name);
      set(obModel, "value", this.$_.kebabCase(obModel.name));
      set(obModel, "itemClass", "mb-50");

      return obModel;
    });
  }

  get logged(): boolean {
    return AuthModule.isLogged;
  }

  get canAccess(): boolean {
    if (!this.logged || !AuthModule.courses.length) {
      return false;
    }

    return (
      filter(AuthModule.courses, (obCourse) => obCourse.id === this.course.id)
        .length > 0
    );
  }

  mounted(): void {
    this.$nextTick(this.load);
  }

  async load(): Promise<void> {
    if (!this.course || !this.course.id) {
      return;
    }

    this.obSections = new SectionCollection();
    await this.obSections
      .filterBy({ course: this.course.id, active: 1 })
      .fetch();

    if (this.canAccess) {
      each(this.obSections.models, (obModel: Section, index: number) => {
        if (obModel.get("student_progress")) {
          this.iActiveItem = index;
        }
      });
    }

    this.$emit("sections", this.obSections.length);
  }
}
