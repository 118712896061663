
import { Vue, Component, Prop } from "vue-property-decorator";
import VFancyBox from "@/components/common/VFancyBox.vue";
import { v4 as uuidv4 } from "uuid";
import LoginForm from "@/modules/login/components/LoginForm.vue";
import { Article } from "@planetadeleste/vue-mc-goodnews";
import { Fancybox } from "@fancyapps/ui";
import { EventBus } from "@/services/event-bus";
import CourseAddToCartBlock from "@/modules/courses/components/course/CourseAddToCartBlock.vue";
import { Course } from "@planetadeleste/vue-mc-learning";
import EBtn from "@/components/common/EBtn.vue";
import LinkBtn from "@/components/common/LinkBtn.vue";
import { AuthModule } from "@/store/auth";
import { get } from "lodash";

@Component({
  components: { LinkBtn, EBtn, CourseAddToCartBlock, VFancyBox, LoginForm },
})
export default class LoginDialog extends Vue {
  @Prop(Object) readonly css!: Record<string, any> | string[];

  @Prop(Object) readonly item!: Course;

  @Prop(Boolean) readonly preventRedirect!: boolean;

  obOptions: Record<string, any> = {
    closeButton: "outside",
    dragToClose: false,
    keyboard: false,
    trapFocus: false,
    autoFocus: false,
    on: {
      init: this.initFB,
    },
  };

  obArticle: Article = new Article();
  obFancyBox!: InstanceType<typeof Fancybox>;
  bLogin = false;

  mounted(): void {
    EventBus.on("login.success", () => {
      Fancybox.destroy();
    });
    this.$nextTick(this.loadArticle);
  }

  beforeDestroy(): void {
    EventBus.off("login.success");
    Fancybox.destroy();
  }

  initFB(obFancybox: InstanceType<typeof Fancybox>): void {
    this.obFancyBox = obFancybox;
  }

  async loadArticle(): Promise<void> {
    this.obArticle = new Article({ slug: "login" });
    await this.obArticle.fetch();
  }

  get bgImg(): string | null {
    return this.obArticle && this.obArticle.preview_image
      ? this.obArticle.preview_image
      : null;
  }

  get free(): boolean {
    return get(this.item, "free", false);
  }

  get elId(): string {
    return uuidv4();
  }

  get logged(): boolean {
    return AuthModule.isLogged;
  }
}
