
import { FileAwsData } from "@planetadeleste/vue-mc-learning";
import { Vue, Component, Prop } from "vue-property-decorator";
import VPlayer from "@/components/VPlayer.vue";
import VFancyBox from "@/components/common/VFancyBox.vue";
import VPlyr from "@/components/VPlyr.vue";
import { EventBus } from "@/services/event-bus";
import { v4 as uuidv4 } from "uuid";

@Component({
  components: { VPlayer, VPlyr, VFancyBox },
})
export default class VPlayerDialog extends Vue {
  obOptions: Record<string, any> = {
    closeButton: "outside",
    dragToClose: false,
    keyboard: false,
    trapFocus: false,
    autoFocus: false,
    on: {
      done: this.reveal,
      closing: this.close,
      destroy: this.onBeforeClose,
    },
  };
  displayVideo = false;

  @Prop(Object) readonly video!: FileAwsData;
  @Prop(String) readonly src!: string;
  @Prop(String) readonly poster!: string;
  @Prop(String) readonly name!: string;
  @Prop(Object) readonly css!: Record<string, any> | string[];
  @Prop(Boolean) readonly plyr!: boolean;
  @Prop(Number) readonly itemId!: number;
  @Prop(Number) readonly seekTime!: number;
  @Prop({ type: Object, default: () => ({}) }) readonly videoOptions!: Record<
    string,
    any
  >;
  @Prop({ type: String, default: "a" }) readonly tag!: string;

  onBeforeOpen(): void {
    this.displayVideo = true;
    this.$emit("opening", this.itemId);
  }

  onCurrentTime(fTime: number): void {
    this.$emit("timeupdate", fTime);
  }

  reveal(): void {
    this.$_.delay(() => {
      EventBus.emit("show.video");
    }, 500);
  }

  close(): void {
    EventBus.emit("hide.video");
    this.displayVideo = false;
  }

  onBeforeClose(): void {
    const obElement = document.getElementById(this.unId);
    if (!obElement) {
      return;
    }

    const arElements = obElement.querySelectorAll("video");
    this.$_.each(arElements, (obElement) => obElement.remove());
  }

  get unId(): string {
    return uuidv4();
  }

  get sComponent(): string {
    return this.plyr ? "v-plyr" : "v-player";
  }
}
