
import {
  Course,
  CourseData,
  LessonCollection,
  SectionData,
} from "@planetadeleste/vue-mc-learning";
import { Component, Mixins, Prop } from "vue-property-decorator";
import CourseLessonItem from "@/modules/courses/components/course/CourseLessonItem.vue";
import VPlayerDialog from "@/components/VPlayerDialog.vue";
import AuthMixin from "@/mixins/AuthMixin";
import LoginDialog from "@/modules/login/components/LoginDialog.vue";
import { isSubscribed } from "@/services/helpers";
import CourseVideo from "@/modules/courses/components/course/CourseVideo.vue";

@Component({
  components: { CourseVideo, CourseLessonItem, VPlayerDialog, LoginDialog },
})
export default class CourseLessons extends Mixins(AuthMixin) {
  @Prop(Object) readonly section!: SectionData;

  @Prop(Object) readonly course!: CourseData | Course;
  obLessons: LessonCollection = new LessonCollection();

  get cssCourseItem(): Record<string, any> {
    return {
      "course__curriculum-content": true,
    };
  }

  get canAccess(): boolean {
    return this.logged && isSubscribed(this.course.id);
  }

  mounted(): void {
    this.$nextTick(this.load);
  }

  async load(): Promise<void> {
    if (!this.section || !this.section.id) {
      return;
    }

    this.obLessons = new LessonCollection();
    await this.obLessons
      .filterBy({ section: this.section.id, active: 1 })
      .fetch();
  }
}
