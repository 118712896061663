
import { Component, Prop, Vue } from "vue-property-decorator";
import { Course } from "@planetadeleste/vue-mc-learning";
import { OfferData, ProductData } from "@planetadeleste/vue-mc-shopaholic";
import { first } from "lodash";
import AddToCart from "@/components/cart/AddToCart.vue";

@Component({
  components: { AddToCart },
})
export default class CourseAddToCartBlock extends Vue {
  @Prop(Object) readonly item!: Course;

  get product(): ProductData | null {
    return this.item.get("product");
  }

  get offer(): OfferData | undefined | null {
    return this.product ? first(this.product.offers) : null;
  }
}
