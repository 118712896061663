
import { Component, Prop, Vue } from "vue-property-decorator";
import { RouteConfigLocal } from "@/router/routes";
import { set } from "lodash";

@Component({ name: "link-btn" })
export default class LinkBtn extends Vue {
  @Prop([String, Object]) readonly to!: RouteConfigLocal;

  get sType(): string {
    return this.to ? "router-link" : "a";
  }

  get obAttrs(): Record<string, any> {
    const obData = this.$attrs;
    if (this.to) {
      set(obData, "to", this.to);
    }

    return obData;
  }
}
