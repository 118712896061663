
import AuthMixin from "@/mixins/AuthMixin";
import { LessonData } from "@planetadeleste/vue-mc-learning";
import { Component, Prop, Mixins } from "vue-property-decorator";

@Component
export default class CourseLessonItem extends Mixins(AuthMixin) {
  @Prop(Object) readonly lesson!: LessonData;

  get icon(): string {
    return `icon-${this.lesson.is_public ? "un" : ""}lock`;
  }

  get iconCss(): Record<string, any> {
    return {
      "ml-10": true,
      "text-success": this.done,
    };
  }

  get progress(): number | null {
    if (!this.user) {
      return null;
    }

    return this.$_.get(this.lesson, "student_progress");
  }

  get done(): boolean {
    return !!this.progress && this.progress >= 100;
  }
}
