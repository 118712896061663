
import { Lesson, Student } from "@planetadeleste/vue-mc-learning";
import { Component, Mixins, Prop } from "vue-property-decorator";
import AuthMixin from "@/mixins/AuthMixin";
import VPlayerDialog from "@/components/VPlayerDialog.vue";
import CourseLessonItem from "@/modules/courses/components/course/CourseLessonItem.vue";

type FnThrottle = (fTime: number) => Promise<void> | undefined;

@Component({
  components: { CourseLessonItem, VPlayerDialog },
})
export default class CourseVideo extends Mixins(AuthMixin) {
  @Prop(Object) readonly lesson!: Lesson;
  @Prop(Object) readonly css!: Record<string, any> | string[];
  @Prop({ type: String, default: "a" }) readonly tag!: string;
  fnThrottleTimer!: FnThrottle;

  get iLessonId(): number | undefined {
    return this.lesson ? this.lesson.id : undefined;
  }

  created(): void {
    this.fnThrottleTimer = this.$_.throttle(this.playing, 3000);
  }

  onTimeUpdate(fTime: number): void {
    this.fnThrottleTimer(fTime);
  }

  onOpen(id: number): void {
    this.$emit("open", id);
  }

  async playing(fTime: number): Promise<void> {
    if (!this.logged || !this.iLessonId) {
      return;
    }

    const obStudent = new Student();
    const obResponse = await obStudent.playing(this.iLessonId, fTime);
    if (obResponse) {
      const obEnrollData = obResponse.getData().data;
      this.lesson.set(
        "student_progress",
        this.$_.get(obEnrollData, "progress", 0)
      );
      this.lesson.set(
        "student_played",
        this.$_.get(obEnrollData, "duration", 0)
      );
    }
  }

  getSeekTime(obLesson: Lesson): number {
    if (
      !this.logged ||
      !obLesson.get("student_progress") ||
      obLesson.get("student_progress") > 95
    ) {
      return 0;
    }

    return obLesson.get("student_played");
  }
}
