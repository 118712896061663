
import {
  Course,
  Lesson,
  LessonCollection,
  SectionCollection,
} from "@planetadeleste/vue-mc-learning";
import { Component, Prop, Vue } from "vue-property-decorator";
import CourseRating from "@/modules/courses/components/course/CourseRating.vue";
import CourseSections from "@/modules/courses/components/course/CourseSections.vue";
import CourseSidebar from "@/modules/courses/components/course/CourseSidebar.vue";
import CourseReview from "@/modules/courses/components/course/CourseReview.vue";
import { AuthModule } from "@/store/auth";
import { v4 as uuidv4 } from "uuid";
import CourseAddToCartBlock from "@/modules/courses/components/course/CourseAddToCartBlock.vue";
import { isSubscribed } from "@/services/helpers";
import VPlayerDialog from "@/components/VPlayerDialog.vue";
import CourseVideo from "@/modules/courses/components/course/CourseVideo.vue";
import LoginDialog from "@/modules/login/components/LoginDialog.vue";
import { EventBus } from "@/services/event-bus";

@Component({
  components: {
    LoginDialog,
    CourseVideo,
    VPlayerDialog,
    CourseAddToCartBlock,
    CourseRating,
    CourseSections,
    CourseSidebar,
    CourseReview,
  },
})
export default class CourseView extends Vue {
  @Prop(Object) readonly item!: Course;
  @Prop(String) readonly title!: string;
  @Prop(Boolean) readonly hideTitle!: boolean;

  iSelectedTab = 0;
  iCourseSections = 0;
  iCourseLessons = 0;
  arTabItems: Record<string, any>[] = [
    { label: "course.tab.info", icon: "icon_ribbon_alt" },
    { label: "course.tab.lessons", icon: "icon_book_alt" },
    { label: "course.tab.review", icon: "icon_star_alt" },
  ];

  obLesson: Lesson | null | undefined = null;

  get updatedAt(): string | null {
    return this.item && this.item.updated_at
      ? this.$moment(this.item.updated_at).format("lll")
      : null;
  }

  get previewImage(): string {
    return this.item.preview_image;
  }

  get summary(): string {
    const arItems: string[] = [];

    if (!this.item) {
      return "";
    }

    arItems.push(`${this.item.sections_count} módulos`);
    arItems.push(`${this.item.lessons_count} clases`);
    arItems.push(`${this.item.duration} de duración total`);

    return this.$_.join(arItems, " • ");
  }

  get courseTitle(): string {
    return this.title || this.item.name;
  }

  get logged(): boolean {
    return AuthModule.isLogged;
  }

  get hasLessons(): boolean {
    return this.item.lessons_count > 0;
  }

  get canAccess(): boolean {
    return this.logged && isSubscribed(this.item.id) && this.hasLessons;
  }

  get tabContentId(): string {
    return uuidv4();
  }

  get lastItemTab(): number {
    return this.arTabItems.length - 1;
  }

  get cssCourseItem(): Record<string, any> {
    return {
      "nav-link": true,
    };
  }

  // get video() {
  //   if (!this.hasLessons || this.item.lessons_count > 1) {
  //     return undefined;
  //   }
  // }

  created(): void {
    EventBus.on("login.success", async () => {
      this.obLesson = null;

      if (!this.logged || !this.hasLessons || this.item.lessons_count > 1) {
        return;
      }

      await this.loadLesson();
      this.tabActivate(1);
    });
  }

  beforeDestroy(): void {
    EventBus.off("login.success");
  }

  mounted(): void {
    if (!this.hasLessons) {
      this.arTabItems.splice(1, 1);
    }

    this.loadLesson();

    this.$nextTick(() => {
      if (this.canAccess && this.$route.name == "courses.view") {
        this.tabActivate(1);
        this.$_.delay(() => {
          const elPanelContent = document.getElementById(this.tabContentId);
          if (elPanelContent) {
            this.$scrollTo(elPanelContent, 500, { offset: -95 });
          }
        }, 1000);
      }
    });
  }

  tabActivate(iIndex: number): void {
    this.iSelectedTab = iIndex;
  }

  onSectionsLoaded(iLength: number): void {
    this.iCourseSections = iLength;
  }

  async loadLesson() {
    if (!this.hasLessons || this.item.lessons_count > 1) {
      return;
    }

    const obSections = new SectionCollection();
    await obSections.filterBy({ course: this.item.id, active: 1 }).fetch();
    const obSection = obSections.first();

    if (!obSection) {
      return;
    }

    const obLessons = new LessonCollection();
    await obLessons.filterBy({ section: obSection.id, active: 1 }).fetch();
    this.obLesson = obLessons.first();
  }
}
