
import { FileAwsData } from "@planetadeleste/vue-mc-learning";
import { Vue, Component, Prop } from "vue-property-decorator";
import { v4 as uuidv4 } from "uuid";
import Plyr, { Options } from "plyr";
import { EventBus } from "@/services/event-bus";
import "plyr/dist/plyr.css";

@Component
export default class VPlyr extends Vue {
  player!: Plyr;

  @Prop(Object) readonly video!: FileAwsData;
  @Prop(String) readonly src!: string;
  @Prop(String) readonly poster!: string;
  @Prop(Number) readonly seekTime!: number;
  @Prop({ type: Object, default: () => ({}) }) readonly options!: Record<
    string,
    any
  >;

  //   @Ref("player") readonly obPlayer!: InstanceType<typeof VuePlyr>;

  mounted(): void {
    this.$nextTick(() => {
      if (!this.obElement) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.player = new Plyr(this.obElement, this.obOptions);

      this.player.on("ready", () => {
        if (this.playerSeekTime) {
          this.player.currentTime = this.playerSeekTime;
        }
      });

      this.player.once("canplay", () => {
        if (this.playerSeekTime) {
          this.player.currentTime = this.playerSeekTime;
        }

        this.player.play();
      });

      this.player.on("timeupdate", (event) => {
        this.$emit("timeupdate", event.detail.plyr.currentTime);
      });

      this.player.on("playing", (event) => {
        this.$emit("playing", event.detail.plyr.currentTime);
      });
    });

    EventBus.on("hide.video", () => {
      if (this.player) {
        this.player.pause();
        this.player.destroy();

        if (this.obElement) {
          this.obElement.remove();
        }
      }
    });

    EventBus.on("show.video", () => {
      // if (this.player && this.seekTime) {
      //   this.player.forward(this.seekTime);
      // }
    });
  }

  beforeDestroy(): void {
    EventBus.off("show.video");
    EventBus.off("hide.video");
    this.player.destroy();
  }

  get obOptions(): Options {
    return this.$_.assign(
      {
        ratio: this.$_.get(this.video.metadata, "display_aspect_ratio", "16:9"),
        listeners: {
          ready() {
            console.log("player ready!");
          },
        },
      },
      this.options
    );
  }

  get playerSeekTime(): number {
    if (!this.video || !this.seekTime) {
      return 0;
    }

    return this.seekTime >= parseInt(this.video.duration) - 10
      ? 0
      : this.seekTime;
  }

  //   get player(): Plyr | false {
  //     return this.obPlayer ? this.obPlayer.player : false;
  //   }

  get elId(): string {
    return uuidv4();
  }

  get obElement(): HTMLElement | null {
    return document.getElementById(this.elId);
  }
}
