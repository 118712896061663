
import AuthMixin from "@/mixins/AuthMixin";
import { ValidationObserver } from "vee-validate";
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import EBtn from "@/components/common/EBtn.vue";
import LinkBtn from "@/components/common/LinkBtn.vue";

@Component({
  components: { LinkBtn, EBtn },
})
export default class LoginForm extends Mixins(AuthMixin) {
  email = "";
  password = "";
  bLoading = false;

  @Ref("observer") readonly obObserver!: InstanceType<
    typeof ValidationObserver
  >;

  get hasSlot(): boolean {
    return !this.$_.isEmpty(this.$slots);
  }

  mounted(): void {
    this.$nextTick(() => {
      this.email = this.user.email;
    });
  }

  async onLogin(): Promise<void> {
    const valid = await this.obObserver.validate();
    if (valid && !this.bLoading) {
      this.bLoading = true;

      await this.logIn(this.email, this.password).catch((error) => {
        this.processError(error);
      });

      this.bLoading = false;
    }
  }
}
