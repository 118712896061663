
import { Course, FileAwsData } from "@planetadeleste/vue-mc-learning";
import { Vue, Component, Prop } from "vue-property-decorator";
import VPlayerDialog from "@/components/VPlayerDialog.vue";

@Component({
  components: { VPlayerDialog },
})
export default class CourseSidebar extends Vue {
  @Prop({ type: Object, default: () => new Course() }) readonly item!: Course;

  get video(): FileAwsData {
    return this.item.get("video");
  }
}
